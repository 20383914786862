import React from 'react'
import axios from 'axios'
import { withRouter } from "react-router";
import {
    Grid,
    Button,
    TextField,
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {sendPhone} from './redux/action'
import { connect } from "react-redux";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Register extends React.Component{

    state = {
        name: '',
        email: '',
        password: '',
        rePassword:'',
        code: "",
        open: false,
        message: '',
        severity:'',
    };

    handleChange = event => {
        // event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({open: false});
    };

    handleClick = async() => {
        const {name, email, password, rePassword, code} = this.state
        if((name ==+ '') || (email ==+ '') || (password ==+ '') || (rePassword ==+ '') || (code ==+ '')) {
            await this.setState({open: true, message: 'وارد کردن همه ی فیلد ها الزامی است', severity:'error'});
        }else{
            if(!this.validateEmail(this.state.email)){
                await this.setState({open: true, message: 'ایمیل وارد شده نا معتبر است!', severity:'error'});
            }else if(password.length<4){
                await this.setState({open: true, message: 'رمز عبور حداقل باید چهار کاراکتر باشد', severity:'error'});
            }else if(password !== rePassword){
                await this.setState({open: true, message: 'رمزعبور های وارد شده یکسان نیستند!', severity:'error'});
            }else {
                let self = this
                await axios({
                    method: 'get',
                    url: `http://www.parvareshfekr.ir/exRegister/register?phone=${this.props.phoneNumber}&code=${code}&name=${name}&email=${email}&password=${password}&re-password=${rePassword}&agentCode=${this.props.match.params.id}`,
                    headers: {},
                }).then(async result => {
                    console.log('data manager list',result) 
                    if(result.data.code == 200){
                        await self.setState({open: true, message: result.data.message, severity:'success'});
                        window.open("https://apps.apple.com/us/app/%D9%BE%D8%B1%D9%88%D8%B1%D8%B4-%D8%A7%D9%81%DA%A9%D8%A7%D8%B1/id1171466499", "_self")
                    }else{
                        self.setState({open: true, message: result.data.message, severity:'error'})
                    }
                }).catch(error => {
                    console.log('error',error)
                    self.setState({open: true, message: 'خطا در برقراری ارتباط با سرور!', severity:'error'})
                })
            }
        }
    };

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        console.log('phone num',this.props.phoneNumber)
        return(
            <div className="signup">
                <Snackbar open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
                    <Alert  severity={this.state.severity}>
                    {this.state.message}
                    </Alert>
                </Snackbar>
                <div className="main">
                    <div className="signup-card">
                        <Grid style={{padding: '25px'}} container spacing={3} >
                            <Grid container item justify='center' alignItems="center" xs={12}>
                                <img className='logo' alt="پرورش افکار" src="../logo.jpg" />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    InputLabelProps={{
                                        style:{fontFamily:'sans', fontSize:'13px', left:'unset'}
                                    }}
                                    fullWidth
                                    label= 'کد فعالسازی'
                                    type='text'
                                    inputProps={{
                                        style: {fontFamily:'sans', fontSize:'12px'}
                                    }}
                                    onChange={(e) => this.setState({code: e.target.value})}
                                    value= {this.state.code}
                                />       
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    InputLabelProps={{
                                        style:{fontFamily:'sans', fontSize:'13px', left:'unset'}
                                    }}
                                    fullWidth
                                    label= 'نام و نام خانوادگی'
                                    type='name'
                                    inputProps={{
                                        style: {fontFamily:'sans', fontSize:'12px'}
                                    }}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    value= {this.state.name}
                                />       
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    InputLabelProps={{
                                        style:{fontFamily:'sans', fontSize:'13px', left:'unset'}
                                    }}
                                    fullWidth
                                    label= 'ایمیل'
                                    type='email'
                                    inputProps={{
                                        style: {fontFamily:'sans', fontSize:'12px'}
                                    }}
                                    onChange={(e) => this.setState({email: e.target.value})}
                                    value= {this.state.email}
                                />       
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    InputLabelProps={{
                                        style:{fontFamily:'sans', fontSize:'13px', left:'unset'}
                                    }}
                                    fullWidth
                                    type='password'
                                    label= 'رمزعبور'
                                    inputProps={{
                                        style: {fontFamily:'sans', fontSize:'12px'}
                                    }}
                                    onChange={(e) => this.setState({password: e.target.value})}
                                    value= {this.state.password}
                                />       
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    InputLabelProps={{
                                        style:{fontFamily:'sans', fontSize:'13px', left:'unset'}
                                    }}
                                    fullWidth
                                    type='password'
                                    label= 'تکرار رمزعبور'
                                    inputProps={{
                                        style: {fontFamily:'sans', fontSize:'12px'}
                                    }}
                                    onChange={(e) => this.setState({rePassword: e.target.value})}
                                    value= {this.state.rePassword}
                                />       
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}></Grid>
                            <Grid style={{textAlign:'left'}} item xs={12}>
                                <Button onClick={this.handleClick} variant="contained" color="primary">
                                    ثبت نام
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    phoneNumber : state.phoneNumber
});
 
export default withRouter(connect(mapStateToProps, {sendPhone})(Register))