import React from 'react';
import SignUp from './SignUp'
import Register from './register'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

class App extends React.Component {

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/signup/:id" children={<SignUp />} />
            <Route path="/register/:id" children={<Register />} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
