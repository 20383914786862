import {sendPhone} from './action'

const initialState = {
    phoneNumber: ''
}

const Reducer = function(state = initialState, action) {
    return {
        ...state,
        phoneNumber: action.data
    };
}

export default Reducer;
