import React from 'react';
import axios from 'axios'
import { withRouter } from "react-router";
import {
    Grid,
    Button,
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {NumberInput} from "react-hichestan-numberinput";
import './Signup.css'
import {sendPhone} from './redux/action'
import { connect } from "react-redux";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  

class SignUp extends React.Component {

    componentDidMount() {
        ValidatorForm.addValidationRule('isPhoneNumber', (value) => {
            if (value.length !== 11) {
                return false;
            }
            return true;
        });
    }

    state = {
        phoneNumber: "",
        open: false,
        message: '',
        severity:'',
    };

    handleChange = event => {
        // event.persist();
        this.props.sendPhone(event.target.value)
        // this.setState({
        //     [event.target.name]: event.target.value
        // });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({open: false});
    };

    handleFormSubmit = async () => {
        let self = this
        await axios({
            method: 'get',
            url: `http://www.parvareshfekr.ir/exRegister/sendPhone?phone=${this.props.phoneNumber}`,
            headers: {},
        }).then(async result => {
            console.log('data manager list',result) 
            if(result.data.code == 200){
                await self.setState({open: true, message: result.data.message, severity:'success'});
                this.props.history.push(`/register/${this.props.match.params.id}`)
            }else{
                self.setState({open: true, message: result.data.message, severity:'error'})
            }
        }).catch(error => {
            console.log('error',error)
            self.setState({open: true, message: 'خطا در برقراری ارتباط با سرور!', severity:'error'})
        })
    };

  render() {
    const classes = this.props
    let { phoneNumber } = this.state;
    console.log('id',this.props.match.params.id)
    return (
        <div className="signup">
            <Snackbar open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
                <Alert  severity={this.state.severity}>
                {this.state.message}
                </Alert>
            </Snackbar>
            <div className="main">
                <div className="signup-card">
                <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={6}>
                        <Grid item xs={12}>
                            <img className='logo' alt="پرورش افکار" src="../logo.jpg" />
                        </Grid>
                        <Grid container item direction="column" justify="center" alignItems="center" xs={12} spacing={4}>
                            <Grid item xs={12} dir='rtl'>
                                <TextValidator
                                    InputLabelProps={{
                                        style:{fontFamily:'sans', fontSize:'12px', left:'unset'}
                                    }}
                                    label="شماره موبایل"
                                    onChange={this.handleChange}
                                    name="phoneNumber"
                                    InputProps={{
                                        inputComponent: NumberInput,
                                        inputProps: {maxLength: 12},
                                    }}
                                    type="tel"
                                    value={this.props.phoneNumber}
                                    validators={["required", "isPhoneNumber"]}
                                    errorMessages={[
                                        "این فیلد اجباریست", "این شماره موبایل معتبر نیست"
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button style={{fontSize:'14px'}} variant="contained" color="primary" type="submit">
                                    ارسال کد تایید
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = state => ({
   phoneNumber : state.phoneNumber
});

// export default withRouter(SignUp);
export default withRouter(connect(mapStateToProps, {sendPhone})(SignUp))